import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql } from "gatsby";
import { any, exact, string } from "prop-types";
import React from "react";

import MinPageHeightWrapper from "Components/MinPageHeightWrapper";
import { richTextOptions } from "Services/helper-utils/rich-text";
import Styles from "./styles.css";

const PagePlainContent = (props) => {
	const { title, body } = props;
	return (
		<>
			<MinPageHeightWrapper style={Styles.wrapper}>
				<div css={Styles.paddingWrapper}>
					<div css={Styles.headingWrapper}>
						<h2 css={Styles.heading}>{title}</h2>
					</div>
				</div>
			</MinPageHeightWrapper>
			<MinPageHeightWrapper>
				<div css={Styles.body}>
					{documentToReactComponents(body.json, richTextOptions)}
				</div>
			</MinPageHeightWrapper>
		</>
	);
};

PagePlainContent.propTypes = {
	title: string,
	description: exact({
		description: string,
	}),
	body: exact({
		json: any,
	}),
};

export default PagePlainContent;

export const query = graphql`
	fragment PagePlainQuery on ContentfulPagePlain {
		title
		description {
			description
		}
		body {
			json
		}
	}
`;
