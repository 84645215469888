import { graphql } from "gatsby";
import React from "react";

import PagePlainContent from "Components/PagePlainContent";
import SEO from "Components/SEO";
import Wrapper from "Components/Wrapper";

const PlainPage = ({
	data: { content, header, footer, legal, loginResorts, products },
	pageContext: { langKey },
}) => (
	<Wrapper
		lang={langKey}
		footer={footer}
		header={header}
		legal={legal.edges}
		loginResorts={loginResorts.edges}
		pageType="plain"
		products={products.edges}>
		<SEO
			description={
				(content.description && content.description.description) || ""
			}
			lang={langKey}
			title={content.title}
		/>
		<PagePlainContent {...content} />
	</Wrapper>
);

export default PlainPage;

export const query = graphql`
	query plainPageQuery($slug: String, $langKey: String) {
		content: contentfulPagePlain(
			node_locale: { eq: $langKey }
			slug: { eq: $slug }
		) {
			...PagePlainQuery
		}
		header: contentfulHeader(node_locale: { eq: $langKey }) {
			...HeaderQuery
		}
		footer: contentfulFooter(node_locale: { eq: $langKey }) {
			...FooterQuery
		}
		loginResorts: allContentfulResortLogin(
			sort: { fields: title }
			filter: { node_locale: { eq: $langKey } }
		) {
			edges {
				node {
					slug
					title
				}
			}
		}
		products: allContentfulProduct(
			filter: { node_locale: { eq: $langKey } }
			sort: { fields: orderHint }
		) {
			edges {
				node {
					...ProductsMenuQuery
				}
			}
		}
		legal: allContentfulPagePlain(
			filter: { node_locale: { eq: $langKey } }
			sort: { fields: slug }
		) {
			edges {
				node {
					...PlainPagesMenuQuery
				}
			}
		}
	}
`;
