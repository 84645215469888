import styling from "Services/styling";

export default {
	wrapper: {
		alignItems: "start",
		"& > *": {
			width: "100%",
		},
	},
	paddingWrapper: {
		position: "relative",
		zIndex: 1,
		...styling.mixins.padding(0, 20),
		[styling.sizes.breakpoints.medium]: {
			...styling.mixins.padding(0, 60),
		},
	},
	headingWrapper: {
		position: "relative",
	},
	heading: {
		fontSize: "5rem",
		lineHeight: "5rem",
		fontWeight: 700,
		position: "relative",
		zIndex: 1,
		...styling.mixins.margin(0),
		[styling.sizes.breakpoints.small]: {
			fontSize: "7rem",
			lineHeight: "7rem",
		},
		[styling.sizes.breakpoints.medium]: {
			width: "50%",
			fontSize: "10rem",
			lineHeight: "10rem",
			letterSpacing: "-0.15rem",
		},
	},
	body: {
		textAlign: "left",
		fontSize: "1.4rem",
		lineHeight: "1.8rem",
		p: {
			...styling.mixins.margin(0, 0, 14),
		},
		ol: {
			listStyle: "decimal",
			...styling.mixins.padding(0, 0, 0, 20),
			...styling.mixins.margin(0, 0, 7),
		},
		ul: {
			listStyle: "disc",
			...styling.mixins.padding(0, 0, 0, 20),
			...styling.mixins.margin(0, 0, 7),
		},
	},
};
